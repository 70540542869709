<style lang="scss" scoped>
@import "@/styles/_colors.scss";
.ant-pagination {
  align-self: center;
  padding: 20px;
}

::v-deep {
  .ant-pagination-item-active {
    background: $simpatra-orange !important;
    a {
      color: white;
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item:hover {
    border-color: $simpatra-orange !important;
  }

  .ant-pagination-item:hover a {
    color: #374458;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none !important;
    background: transparent;
    letter-spacing: 1px;
  }
  .ant-pagination-prev:not(.ant-pagination-disabled),
  .ant-pagination-next:not(.ant-pagination-disabled) {
    .ant-pagination-item-link {
      color: $dark-grey !important;
      &:hover {
        color: $simpatra-orange !important;
      }
    }
  }
  .ant-pagination-prev .ant-pagination-item-link::before {
    content: "Previous";
  }
  .ant-pagination-next .ant-pagination-item-link::before {
    content: "Next";
  }
  .ant-pagination-item-link i {
    display: none;
  }
}
</style>
<template>
  <a-pagination :current="defaultCurrentPage" :default-page-size="pageSize" :total="total" @change="handlePagination" />
</template>
<script>
export default {
  name: "Pagination",
  props: {
    handlePagination: Function,
    defaultCurrentPage: Number,
    pageSize: Number,
    total: Number
  }
};
</script>

