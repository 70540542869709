<template>
  <div
    class="content-wrapper"
    :class="{
      'content-loading': isLoading,
    }"
  >
    <slot name="content" />
    <Loading v-if="isLoading" :text-info="textInfo" />
  </div>
</template>

<script>
import Loading from "@/components/common/AntDesign/Loading.vue";

export default {
  name: "BaseComponent",
  components: {
    Loading,
  },
  props: {
    isLoading: Boolean,
    textInfo: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper.content-loading {
  position: relative !important;
  border: none;
  min-height: 12em;
}

.content-wrapper .loading {
  position: absolute;
  z-index: 2;
  background-color: #f9f9f9;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.3s;
  opacity: 0.9;
}
</style>
