<style lang="scss" scoped>
@import "./FormField/FormField.scss";
@import "@/components/shared-components/FormField/FormField.scss";
</style>
<template>
  <form-group :label="formLabel" :validator="validator">
    <label v-if="customLabel">
      {{ customLabel }}
      <p v-if="suffixLabel" class="suffix-label">{{ suffixLabel }}</p>
      <span v-if="isRequired" class="asterisk">*</span>
    </label>
    <label v-if="customAlertText" class="custom-alert-text">
      {{ customAlertText }}
      <p v-if="suffixLabel" class="suffix-label">{{ suffixLabel }}</p>
    </label>
    <template v-if="useSlot">
      <slot name="component" />
    </template>
    <template v-else>
      <!-- INPUT -->
      <!-- DIDN'T USED "A-INPUT" BECAUSE OF MIN-LENGTH -->
      <div v-if="fieldType === 'input'" class="custom-input-field ant-input">
        <input
          :id="fieldId"
          :class="{ 'is-invalid': validator && validator.$error }"
          class="form-control"
          type="text"
          :value="defaultValue"
          :minlength="minLength"
          :maxlength="maxlength"
          :placeholder="placeholder"
          @keypress="validateInput($event)"
          @input="handleInput($event.target.value)"
        />
        <template v-if="suffixIcon">
          <img v-if="imgIcon" :src="suffixIcon" class="img-icon" />
          <font-awesome-icon v-else :icon="suffixIcon" />
        </template>
        <!-- <font-awesome-icon v-if="suffixIcon" :icon="suffixIcon" /> -->
        <font-awesome-icon v-else-if="prefixIcon" :icon="prefixIcon" />
        <span v-else-if="showWordLimit" class="word-limit">
          <p>{{ defaultValue.length }}</p>
          /
          <p>{{ maxlength }}</p>
        </span>
      </div>

      <!-- DATE PICKER -->
      <a-date-picker
        v-else-if="fieldType === 'date-picker'"
        :id="fieldId"
        v-model="value"
        :format="datePickerFormat"
        :placeholder="placeholder"
        :disabled-date="disabledDate"
        @change="handleChange"
      >
        <template slot="dateRender" slot-scope="current, today">
          <div class="ant-calendar-date" :style="getCurrentStyle(current, today)">
            {{ current.date() }}
          </div>
        </template>
      </a-date-picker>
      <!-- DROPDOWN SELECT -->
      <a-select
        v-else-if="fieldType === 'select-option'"
        :id="fieldId"
        :class="{ 'is-invalid': validator && validator.$error }"
        show-search
        :placeholder="placeholder"
        :default-active-first-option="false"
        option-filter-prop="children"
        :default-value="defaultValue || undefined"
        :disabled="isDisabled"
        :allow-clear="allowClear"
      >
        <a-select-option v-for="option in options" :key="getOptionKey(option)" :value="getOptionValue(option)" @click="handleChange(option)">
          {{ dropdownOptionLabel(option) }}
        </a-select-option>
         <template #clearIcon>
          <!-- custom clear button content -->
          <a-icon type="close-circle" @click="handleChange(null)" />
        </template>
      </a-select>
    </template>
  </form-group>
</template>

<script>
import moment from "moment";
// eslint-disable-next-line import/extensions
import ValidationHelper from "@/utils/validation-helper.js";

export default {
  name: "FormField",
  props: {
    handleInput: Function,
    handleChange: Function,
    model: String,
    options: Array,
    validator: Object,
    fieldId: String,
    formLabel: String,
    customLabel: String,
    customAlertText: String,
    isRequired: Boolean,
    fieldType: {
      type: String,
      default: "input",
    },
    disabledFutureDays: {
      type: Boolean,
      default: false,
    },
    minLength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    defaultValue: {
      type: [String, Number, Boolean, Object],
      default: undefined,
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    customOptionValue: {
      type: String,
      default: null,
    },
    customOptionKey: {
      type: String,
      default: null,
    },
    useSlot: {
      type: Boolean,
      default: false,
    },
    prefixIcon: {
      type: [String, Array],
      default: null,
    },
    suffixIcon: {
      type: [String, Array],
      default: null,
    },
    imgIcon: {
      type: Boolean,
      default: null,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    customOptionLabel: Function,
    isDisabled: Boolean,
    datePickerFormat: {
      type: String,
      default: "YYYY/MM/DD",
    },
    suffixLabel: String,
    allowClear: Boolean
  },
  computed: {
    value: {
      get() {
        return this.defaultValue;
      },
      set() {},
    },
  },
  methods: {
    getOptionKey(option) {
      return this.customOptionValue ? option[this.customOptionValue] : option.id;
    },
    getOptionValue(option) {
      return this.customOptionValue ? option[this.customOptionValue] : option.id;
    },
    getCurrentStyle(current, today) {
      const style = {};
      if (current.date() === 1) {
        style.border = "1px solid #bbbec4";
        style.borderRadius = "4px";
      }
      return style;
    },
    disabledDate(current) {
      if (this.disabledFutureDays) {
        var startDate = moment(new Date());
        return current > startDate;
      }

      return false;
    },
    validateInput(evt) {
      if (this.isNumber) {
        let validatorUtil = new ValidationHelper();
        if (!validatorUtil.isNumberInput(evt)) {
          evt.preventDefault();
        }
      }
    },
    dropdownOptionLabel(option) {
      return this.customOptionLabel ? this.customOptionLabel(option) : option.name ? option.name : option.value;
    },
  },
};
</script>
