<template>
  <div
    id="select-wrapper"
    ref="selectWrapper"
    class="relative"
    :class="{
      'is-loading': isLoading,
    }"
  >
    <a-select
      v-if="!formValidation"
      ref="selectComponent"
      :value="selectedValue"
      class="select-component"
      style="height: 40px"
      option-filter-prop="children"
      :filter-option="filterOption"
      :disabled="isDisabled || isLoading"
      :placeholder="optionPlaceholder"
      :get-popup-container="trigger => trigger.parentNode"
      show-search
      :allow-clear="allowClear"
      :loading="isLoading || isFiltering"
      @popupScroll="handleScroll"
      @change="handleChange"
      @search="handleSearch"
    >
      <a-select-option v-for="option in options" :key="getId(option)" :value="getId(option)">
        {{ getLabel(option) }}
      </a-select-option>
    </a-select>
    <a-form-item v-else :label="dropdownTitle">
      <a-select
        ref="selectComponent"
        class="select-component"
        :get-popup-container="getPopupContainer"
        :default-value="selectedValue"
        :option-filter-prop="optionFilterProp"
        :show-search="showSearch"
        :filter-option="filterOption"
        :placeholder="optionPlaceholder"
        :disabled="isDisabled"
        @popupScroll="handleScroll"
        @change="handleChange"
        @search="handleSearch"
      >
        <a-select-option v-for="option in options" :key="getId(option)" :value="getId(option)">
          {{ getLabel(option) }}
        </a-select-option>
      </a-select>
    </a-form-item>
  </div>
</template>

<script>
import { debounce } from "vue-debounce";

export default {
  name: "Select",
  components: {},
  props: {
    handleChange: Function,
    handleInifiniteScroll: Function,
    infiniteScrollType: String,
    optionFilterProp: String,
    options: Array,
    optionPlaceholder: {
      type: String,
      default: () => "Select Option",
    },
    dropdownTitle: {
      type: String,
      default: () => "Select Option",
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    defaultValue: {
      type: [String, Number],
      default: () => undefined,
    },
    formValidation: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    configs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isError: false,
      isFiltering: false,
      debouncedFilterOption: null,
    };
  },
  computed: {
    selectedValue() {
      const defaultValue = this.defaultValue || undefined;
      return defaultValue;
    },
  },
  mounted() {
    this.debouncedFilterOption = debounce(this.performFilterOption, 500); // Set the debounce delay to 300ms
  },
  methods: {
    handleSearch: debounce(async function(val) {
      await this.handleInifiniteScroll(this.infiniteScrollType, { name: val });
    }, 500),
    getPopupContainer() {
      return document.getElementById("select-wrapper");
    },
    handleScroll(event) {
      const target = event.target;
      const isScrollAtBottom = (target.scrollHeight - target.scrollTop).toFixed(0) === target.clientHeight.toFixed(0);
      if (this.handleInifiniteScroll && isScrollAtBottom) {
        this.handleInifiniteScroll(this.infiniteScrollType);
      }
    },
    handleScrollEnd() {
      console.log("Scroll reached the end");
      // Perform your desired action here when the scroll reaches the end
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getLabel(option) {
      /**
       * objectProp used for custom values
       */
      if (this.configs && this.configs.objectProp) {
        const { objectProp } = this.configs;
        const { usePropObj, usePropVal1, usePropVal2 } = objectProp;
        const label = usePropObj ? option[usePropObj][usePropVal1] : option[usePropVal1] || option.name;
        const label2 = usePropObj ? option[usePropObj][usePropVal2] : option[usePropVal2];
        return label + (label2 ? ` ${label2}` : "");
      }
      return option.value || option.name;
    },
    getId(option) {
      if (this.configs && this.configs.idKey) {
        return option[this.configs.idKey];
      } else {
        return option.id;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .is-loading {
//   ::v-deep {
//     .ant-select-dropdown-menu::before {
//       content: "Fetching...";
//       background-repeat: no-repeat;
//       background-position: center;
//       position: absolute;
//       height: 100%;
//       background: #80808031;
//       top: 0;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       z-index: 2;
//       backdrop-filter: blur(2px);
//       width: 93%;
//       font-size: 18px;
//       font-weight: 600;
//     }

//     .ant-select-dropdown-menu-item {
//       z-index: 1;
//     }
//   }
// }
</style>
